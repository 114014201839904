/** @jsx jsx */

import { jsx } from 'theme-ui'

import UniversalLink from '../components/universal-link'
import routes from '../constants/routes'

export const halo = {
  title: `Contact us`,
}

export const intro = {
  ...halo,
  route: routes.contactUs,
  description: `Get in touch with the CartaX team.`,
}

export const content = (
  <div sx={{ maxWidth: `var(--maxContentWidth)`, mx: `auto`, py: 5, px: 4 }}>
    <h2 sx={{ mt: 0, mb: 1, fontSize: 5 }}>General questions</h2>
    <p sx={{ mb: 0, fontSize: 3 }}>
      Email <UniversalLink href={routes.emailInfo}>info@cartacapitalmarkets.com</UniversalLink>.
    </p>

    <h2 sx={{ mt: 5, mb: 1, fontSize: 5 }}>Get started</h2>
    <p sx={{ mb: 0, fontSize: 3 }}>
      <UniversalLink to={routes.requestInformation}>Request information</UniversalLink>.
    </p>

    <h2 sx={{ mt: 5, mb: 1, fontSize: 5 }}>Mailing address</h2>
    <address sx={{ mt: `1.25rem`, fontStyle: `normal`, fontSize: 3 }}>
      One World Trade Center <br />
      81st Floor <br />
      New York, NY 10007
    </address>
  </div>
)
